
import Base from '@/mixins/Base.vue';
import { defineComponent } from 'vue';
import FormsReportTypes from '@/components/FormsReportTypes.vue';
import InfringementCategories from '@/components/InfringementCategories.vue';
import Languages from '@/components/TField.vue';

export default defineComponent({
    components: { Languages, InfringementCategories, FormsReportTypes },
    mixins: [Base],
    emits: ['reload'],
    data(): any {
        return {
            item: undefined as any,
            test: 'null',
        };
    },
    mounted(): void {
        this.getItem(this.$route.params.id);
    },
    methods: {
        getItem(id: string): void {
            this.get(`infringements/${id}`).then(({ data }: any) => {
                this.item = data.data;
                (this.$refs.form as any).setValues(this.item);
            });
        },
        saveItem(): void {
            this.put(`infringements/${this.$route.params.id}`, {
                ...this.item,
                name: this.item.translations.name,
                description: this.item.translations.description,
                category_id: this.item.category ? this.item.category.id : null,
            }).then(() => {
                this.$router.push({ name: 'infringements.list' });
                this.$emit('reload');
            });
        },
    },
});
